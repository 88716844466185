
//////SCREEN
.screen {
   display: flex;
   flex-direction: column;
   align-items: center;
   font-family: 'IBM Plex Sans', sans-serif;
   font-size: 18px;
   border-radius: 8px;
   background-color: white;
   border-style: solid;
    
   // background-image: url('../assets/img/bg.png');
   
}


//////HEADER

.logoimg {
   
   padding: 10px;
   width: 270px;
   animation: pulse; /* referring directly to the animation's @keyframe declaration */
   animation-duration: .7s; /* don't forget to set a duration! */
   // border-style: solid;

}

.headercontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
   //  border-style: solid;
   

    width: 97%;
    height: 100%;

}

.headertext {
   padding: 20px;
   animation: pulse; /* referring directly to the animation's @keyframe declaration */
   animation-duration: .7s; /* don't forget to set a duration! */

  
}


//////ANIM


.animcontainer {
   display: flex;
   flex-direction: row;
   justify-content: center;

   // border-style: solid;
   padding: 10px;
   margin: 10px;
   width: '100%'
}

//////MAIN


.maincontainer {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: baseline;

   // border-style: solid;
   margin: 5px;
   width: '100%';
   
   
}

.mobileimg {

   opacity: .5;
   width: 45px;
   height: 80px;
   padding: 10px
   // padding-right: 20px;
}

.mobileimg:hover {
   opacity: 1;
   animation: pulse; /* referring directly to the animation's @keyframe declaration */
   animation-duration: .4s; /* don't forget to set a duration! */
}

.mobileimgselected {
   width: 45px;
   height: 80px;
   padding: 10px;
   opacity: 1
}

.mobileimg:active {
   opacity: .4
}


.webimg {



   opacity: .5;
   width: 220px;
   height: 130px;
   padding: 10px
   // padding-left: 20px
}
.webimg:hover {
   opacity: 1;
   animation: pulse; /* referring directly to the animation's @keyframe declaration */
   animation-duration: .4s; /* don't forget to set a duration! */
}
.webimg:active {
   opacity: .4
}
.webimgselected {
   width: 220px;
   height: 130px;
   padding: 10px;
   opacity: 1
}


///////LINE

.line {
   
   opacity: .3;
      // border-style: solid;
   transition: opacity 2s;
   margin-top: -20px;
}


/////////PORTFOLIO

.portfoliocontainer {
   width: 88%;
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
   margin-bottom: 15px;

}
.webcard {
   width: 40vw;
}

.webcardimg {
   width: 99%;
   border-radius: 4px;
}
.webcardimg:hover {
   width: 100%;
}

////////IMAGECAROUSEL
.imagecontainer {
   // border-width: 1px;
   // border-style: solid;
   width: '100%';
   height: 450px;
   overflow: hidden;
   border-radius: 12px;
   background-image: url('../assets/img/bg.png')
}
.imagecontainer:hover {
   transform: scale(1.05)
}

.webimg {
   padding-bottom: 20px;
}


///////MobilePortfolio
/// 
.mobileportfoliogif {
   width: 250px;
   border-radius: 13px;
   
}

























$poppins: 'Poppins', sans-serif;
$background: #FAFAFC;
$shadow: 0 4px 12px -2px rgba(#6B75A1, .16);
$c-darkGray: #4a4a4e;
$ease: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

.element {
    background-image: url("../assets/img/code.png");
    -webkit-clip-path:
          polygon(
             10% 0, 
             150% 35%, 
             100% 65%, 
             0% 80%);
  clip-path:
          polygon(
             10% 0, 
             150% 35%, 
             100% 65%, 
             0% 80%);
    height: 180px;
    width: 200px;
    transition: $ease;
     animation: poly 10s ease-in-out infinite;
    &:hover {
       // animation: unset;
       filter: hue-rotate(50deg);
    }
 }
 

 
 @keyframes poly {
     0% {
         -webkit-clip-path:
          polygon(
             10% 0,  
             150% 35%, 
             100% 65%, 
             0% 80%);
  clip-path:
     polygon(
        10% 0, 
        150% 35%, 
        100% 65%, 
        0% 80%);
     }
     50% {
         -webkit-clip-path:
          polygon(
             0 0, 
             100% 35%,
             100% 65%, 
             10% 100%);
  clip-path:
     polygon(
        0 0, 
        100% 35%, 
        100% 105%, 
        10% 100%);
     }
     100% {
         -webkit-clip-path:
          polygon(
             10% 0, 
             150% 35%, 
             100% 65%, 
             0% 80%);
  clip-path:
          polygon(
             10% 0, 
             150% 35%, 
             100% 65%, 
             0% 80%);
     }
 }
 

